export function isMobile() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = !!sUserAgent.match(/ipad/i);
    var bIsIphoneOs = !!sUserAgent.match(/iphone os/i);
    var bIsMidp = !!sUserAgent.match(/midp/i);
    var bIsUc7 = !!sUserAgent.match(/rv:1.2.3.4/i);
    var bIsUc = !!sUserAgent.match(/ucweb/i);
    var bIsAndroid = !!sUserAgent.match(/android/i);
    var bIsCE = !!sUserAgent.match(/windows ce/i);
    var bIsWM = !!sUserAgent.match(/windows mobile/i);
    return (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)
}

function isIOS() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = !!sUserAgent.match(/ipad/i);
    var bIsIphoneOs = !!sUserAgent.match(/iphone os/i);
    return bIsIpad || bIsIphoneOs
}

export function doDownload() {
    if (isMobile()) {
        if (isIOS()) {
            if (location.host.toLowerCase().indexOf('fuyin.tv') >= 0) {
                window.location.href = 'https://apps.apple.com/app/fu-yin-ying-shi-wang/id717567955'
            } else {
                window.location.href = 'https://apps.apple.com/cn/app/%E7%A6%8F%E9%9F%B3tv-pro/id6547832227'
            }
        } else {
            window.location.href = 'http://www.fytvapp.net/'
        }
        return true
    }
    return false
}